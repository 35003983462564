@import '@royalaholddelhaize/design-system-pantry-web/utils/mq';

.root {
    display: none;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: var(--color-foreground-inverted-default);

    .line {
        width: 4px;
        height: 4rem;
        background-color: var(--color-foreground-inverted-default);
    }

    @include mq($from: medium) {
        display: flex;
    }
}
