@import '@royalaholddelhaize/design-system-pantry-web/utils/mq';

.root {
    width: 100%;

    .content {
        padding: var(--sizing-core-4) var(--sizing-core-4);
        max-width: var(--grid-width-max);
        margin: 0 auto;

        &.largePadding {
            padding: var(--sizing-core-8) var(--sizing-core-4);
        }

        @include mq($from: medium) {
            padding: var(--sizing-core-8) var(--sizing-core-16);

            &.largePadding {
                padding: var(--sizing-core-14) var(--sizing-core-16);
            }
        }

        @include mq($from: large) {
            padding: var(--sizing-core-14) 8rem;

            &.largePadding {
                padding: var(--sizing-core-28) 8rem;
            }
        }
    }

    img {
        max-width: 100%;
    }

    p,
    li {
        line-height: 1.6;

        @include mq($from: extra-large) {
            font-size: var(--sizing-core-5);
        }
    }
}
