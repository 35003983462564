@import '@royalaholddelhaize/design-system-pantry-web/utils/mq';

.root {
    &.ahDark {
        background-color: var(--color-accent-primary);

        * {
            color: var(--color-foreground-inverted-default);
        }
    }

    &.ahLight {
        background-color: var(--ah-light-color);
    }
}

.content {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: var(--sizing-core-4);

    @include mq($until: medium) {
        &.smallImageBottom {
            flex-direction: column;
        }
    }

    @include mq($from: medium) {
        flex-direction: row;
        gap: var(--sizing-core-10);

        &Left {
            flex-direction: row-reverse;
        }
    }

    @include mq($from: large) {
        gap: var(--sizing-core-20);
    }
}

.descriptionGap {
    gap: var(--sizing-core-2);
}

.descriptionContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-weight: 300;
    width: 100%;

    .largeText & .description,
    .description.secondaryTextColor {
        color: var(--color-foreground-secondary-default);
    }

    @include mq($from: extra-large) {
        .description {
            font-size: var(--sizing-core-5);
            line-height: 1.6;
        }
    }

    @include mq($from: medium) {
        .thirdImageSplit & {
            flex: 2;
        }

        .largeText & {
            .description {
                font-size: var(--sizing-core-6);
                line-height: 1.6;
            }
        }
    }
}

.prefix {
    font-size: var(--sizing-core-4) !important;
    line-height: var(--sizing-core-6);
    font-weight: normal;
}

.title,
.highlightedTitle {
    font-weight: 800;
    font-size: var(--sizing-core-6);
    line-height: var(--sizing-core-8);

    &.titleMedium {
        font-size: var(--sizing-core-8);
        line-height: var(--sizing-core-12);
    }

    &.titleLarge {
        @include mq($from: medium) {
            font-size: var(--sizing-core-8);
            line-height: 1.2;
        }
        @include mq($from: large) {
            font-size: var(--sizing-core-12);
            line-height: 1.2;
        }
        @include mq($from: extra-large) {
            font-size: var(--sizing-core-16);
            line-height: 1.2;
        }
    }
}

.highlightedTitle {
    color: var(--color-accent-primary);
}

.imageContainer {
    display: flex;
    flex: 1;

    @include mq($until: medium) {
        &.smallImageHidden {
            display: none;
        }
    }

    &.centeredImage {
        justify-content: center;
    }

    svg {
        width: 200px;
        height: 200px;

        @include mq($from: medium) {
            width: 300px;
            height: 300px;
        }

        @include mq($from: large) {
            width: 400px;
            height: 400px;
        }
    }

    img,
    video {
        width: 100%;
        height: auto;
        object-fit: cover;

        @include mq($from: medium) {
            max-width: 22rem;
        }
        @include mq($from: large) {
            max-width: 100%;
        }
    }
}

.cta {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--sizing-core-2);
    margin-top: var(--sizing-core-2) !important;
    min-width: var(--spacing-core-12);
    width: fit-content;
    min-height: 48px;
    padding: var(--spacing-core-3) var(--spacing-core-4);
    border-radius: var(--border-radius-core-m);
    box-sizing: border-box;
    border-width: 0;
    cursor: pointer;
    position: relative;
    background: var(--color-action-primary-default);
    color: var(--color-foreground-inverted-default);
    transition: var(--motion-duration-hover);
    text-decoration: none;

    span {
        color: var(--color-foreground-inverted-default);
    }

    @include mq($from: medium) {
        margin-top: var(--sizing-core-5) !important;
    }

    .ahDark & {
        background-color: var(--color-background-primary-default);

        * {
            color: var(--color-accent-primary) !important;
        }

        &:hover:not(:disabled) {
            background-color: var(--color-background-primary-hover);
            box-shadow: inset 0 0 0 1px var(--color-background-primary-hover);
        }

        &:active:not(:disabled) {
            background-color: var(--color-background-primary-pressed);
            box-shadow: inset 0 0 0 1px var(--color-background-primary-pressed);
        }
    }
}
