
// Adding this as temporary color till the new design system is ready
$foreground-secondary: #8499AB;

.list {
    padding: 0;
    margin: 0;
    color: var(--color-foreground-secondary-default);
}

.item {
    list-style: none;
    display: inline-block;
    vertical-align: middle;
    padding-right: var(--sizing-core-8);
    position: relative;

    &:not(:last-child, .backwardsItem)::after {
        content: '';
        width: 10px;
        height: 10px;
        display: inline-block;
        background-image: url('/img/chevron.svg');
        background-repeat: no-repeat;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-5px);
    }
}

.backwardsItem {
    margin-left: 15px;
    fill: var(--color-foreground-secondary-disabled);

    &::after {
        content: '';
        width: 10px;
        height: 10px;
        display: inline-block;
        background-image: url('/img/chevron.svg');
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-5px) translateX(-15px) scaleX(-1);
    }
}

.text,
.link {
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    color: #8499ab;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.link {
    cursor: pointer;

    &:focus,
    &:hover {
        &, & .text {
            color: var(--color-action-primary-default);
            outline: none;
        }
    }

    &:active {
        &, & .text {
            color: var(--color-foreground-primary-default);
        }
    }

}
