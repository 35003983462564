.root {
    display: flex;
    flex-direction: column;
    gap: var(--sizing-core-2);

    p {
        font-weight: 300;
        font-size: var(--sizing-core-5);
        line-height: 1.6;
    }
}
