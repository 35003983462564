@import '@royalaholddelhaize/design-system-pantry-web/utils/mq';

.root {
    background: #b2e6f78a;
    width: 100%;
}

.content {
    padding: var(--sizing-core-4) var(--sizing-core-12);
    max-width: var(--grid-width-max);
    margin: 0 auto;

    @include mq($from: medium) {
        padding: var(--sizing-core-8) var(--sizing-core-16);
    }

    @include mq($from: large) {
        padding: var(--sizing-core-16) 8rem;
    }
    @include mq($from: extra-large) {
        padding: var(--sizing-core-26) 11rem;
    }
}

.text {
    @include mq($from: medium) {
        font-size: var(--sizing-core-8);
        line-height: var(--sizing-core-10);
        font-weight: 800;
    }

    @include mq($from: large) {
        font-size: var(--sizing-core-10);
        line-height: var(--sizing-core-12);
        font-weight: 800;
    }
}
