@import '@royalaholddelhaize/design-system-pantry-web/utils/mq';

.content {
    display: flex;
    flex-direction: column;
    gap: 5rem;

    @include mq($from: medium) {
        gap: 10rem;
    }
}

.sectionImage {
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
        max-width: 100%;
        max-height: 35rem;
        height: auto;
    }
}

.center {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.marqueeWrapper {
    padding-bottom: var(--spacing-core-16);
    padding-top: var(--spacing-core-16);

    @include mq($from: medium) {
        padding-top: 0;
    }
}

.accordion {
    background: var(--color-background-secondary-default);
}

.infoSectionImage {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(7.5deg);
}
