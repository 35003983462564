@import '@royalaholddelhaize/design-system-pantry-web/utils/mq';

.root {
    display: flex;
    flex-direction: column;
    gap: var(--sizing-core-3);
    line-height: 1.6;

    @include mq($from: extra-large) {
        p {
            font-size: var(--sizing-core-5);
        }
    }
}

.imageContainer {
    margin-top: var(--sizing-core-6);
}

.sectionImage {
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
        max-width: 100%;
        max-height: 35rem;
        height: auto;
    }
}
