@import '@royalaholddelhaize/design-system-pantry-web/utils/mq';

.root {
    border-bottom: var(--color-action-primary-default) var(--sizing-core-3)
        solid;
}

.content {
    padding-bottom: 0 !important;
    padding-top: var(--sizing-core-4) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .largeImage {
        max-height: 200px;
        width: auto;
        display: block;
        object-fit: cover;

        &.left {
            object-position: left center;
        }

        &.right {
            object-position: right center;
        }
    }
}
