.root {
    display: flex;
    justify-content: center;
    font-size: var(--sizing-core-16);
    font-weight: 800;

    div {
        display: grid;
        align-self: center;
    }

    > * {
        padding: 0 var(--sizing-core-6);
    }
}
