@import '@royalaholddelhaize/design-system-pantry-web/utils/mq';

.content {
    display: flex;
    flex-direction: column;
    gap: 5rem;
}

.imageShowcase {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 var(--sizing-core-2);
    height: 100%;
    max-height: 12rem;
    gap: var(--sizing-core-8);

    .showcaseImageWrapper {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 100%;

        img {
            width: auto;
            height: 100%;
        }
    }

    @include mq($from: medium) {
        height: 31rem;
        max-height: 31rem;
        padding: 0 var(--sizing-core-6);
        justify-content: space-around;
        gap: var(--sizing-core-20);

        .showcaseImageWrapper:first-child {
            transform: rotate(-5deg);
        }
    }
}

.footer {
    width: 100%;
    position: relative;

    > img {
        width: 100%;
        object-fit: cover;
    }

    &Content {
        width: 100%;
        position: absolute;
        padding: var(--sizing-core-6);
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        img {
            height: 42px;
        }
    }
}
