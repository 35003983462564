@import '@royalaholddelhaize/design-system-pantry-web/utils/mq';

.header {
    position: relative;
    display: flex;
    background-image: url('/img/main-img.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    padding: 4rem 2rem;
    color: var(--color-foreground-inverted-default);

    @include mq($from: small) {
        padding: 8rem 4rem;
    }
    @include mq($from: medium) {
        height: calc(40vh - 5rem);
        padding: 17rem 8rem;
    }
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        254.27deg,
        rgb(0 0 0 / 15.6%) 24.23%,
        rgb(0 0 0 / 52%) 63.52%
    );
}

.headerContent {
    z-index: 1;
    width: 100%;
    max-width: var(--grid-width-max);
    margin: 0 auto;

    > h1 {
        color: var(--color-foreground-inverted-default);
        font-size: var(--sizing-core-5);
        line-height: var(--sizing-core-6);
        font-weight: bold;

        @include mq($from: medium) {
            font-size: var(--sizing-core-8);
            line-height: var(--sizing-core-10);
        }

        @include mq($from: large) {
            font-size: var(--sizing-core-12);
            line-height: var(--sizing-core-14);
            font-weight: 800;
        }
    }
}
