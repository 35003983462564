$accordion-height: 152px;

.root {
    width: 100%;
}

.details {
    overflow: hidden;
    max-height: $accordion-height;

    &.iconOpenAnimation {
        .icon {
            animation: iconOpen var(--motion-duration-core-long);
        }
    }

    &.iconCloseAnimation {
        .icon {
            animation: iconClose var(--motion-duration-core-long);
        }
    }

    .summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--spacing-core-14) 0;
        cursor: pointer;
        user-select: none;
        border-bottom: 1px solid var(--color-foreground-tertiary-default);

        &::marker,
        &::-webkit-details-marker {
            display: none;
        }

        &:focus-visible {
            outline: var(--border-width-core-thick) solid
                var(--color-feedback-keyboard-focus-default);
            outline-offset: -2px;
            transition: none;
            border-radius: var(--border-radius-core-m);
        }

        &:hover {
            transition: background-color var(--motion-duration-core-long)
                ease-in-out;
            background-color: var(--color-background-primary-hover);
        }
    }

    .icon {
        rotate: 0deg;
    }

    &[open] {
        max-height: none;

        // For the animation, calculate the height of the child component to let the animation to what height it should animate.
        &.initOpen {
            > div {
                position: absolute;
                opacity: 0;
            }
        }

        // To show the content when animation has started.
        &.opened {
            > div {
                position: unset;
                opacity: 1;
            }
        }

        .content {
            padding: var(--spacing-core-4) 0;
        }

        .icon {
            rotate: 180deg;
        }
    }
}

@keyframes iconOpen {
    0% {
        rotate: 0deg;
    }

    100% {
        rotate: 180deg;
    }
}

@keyframes iconClose {
    0% {
        rotate: 180deg;
    }

    100% {
        rotate: 0deg;
    }
}
