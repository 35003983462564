.icon {
    width: 2rem;
    height: 2rem;
}

.title {
    display: flex;
    align-items: center;
    gap: var(--spacing-core-4);
}
